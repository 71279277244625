/* You can add HTML default tag styles */


@font-face {
  font-family : 'Regular';
  src         : url('../fonts/Arial-Regular.ttf');
}

@font-face {
  font-family : 'Armenian';
  src         : url('../fonts/MONTSERRAT-REGULAR.TTF');
}

* {
  margin         : 0;
  padding        : 0;
  color          : $black;
  box-sizing     : border-box;
  outline        : 0;
  font-family    : 'Regular', 'Armenian';
  // scroll-behavior: smooth;
}

body {
  font-size        : 16px;
  color            : $black;
  background-color : $body-color;
  // scroll-behavior  : smooth;
  box-sizing       : border-box;

  &.P-disabled {
    overflow: hidden;
  }

  a {
    color : $black;

    &:hover {
      color: $black;
    }
  }
}

a {
  text-decoration : none;
  color           : aliceblue;
}

textarea, input, button, pre {
  font-size   : 16px !important;
  font-family : 'Agenda', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important;
  outline     : none !important;
}

pre {
  white-space : pre-wrap;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  margin     : 0;
  appearance : none;
}

input[type=file] {
  display : none;
}

textarea { resize : none; }
a, button, label { cursor : pointer; }

button {
  border           : 0;
  background-color : transparent;
}

h1, h2, h3, h4, h5, h6 {
  letter-spacing : 4px;
}

h1 { font-size : 40px; }
h2 { font-size : 32px; }
h3 { font-size : 28px; }
h4 { font-size : 24px; }
h5 { font-size : 22px; }
h6 { font-size : 18px; }
a, p {
  font-size : 20px;
  line-height: 30px;
  letter-spacing: .5px;
}

*::-webkit-scrollbar-track, *::-ms-autohiding-scrollbar {
  border-radius    :  2px;
  background-color :  $light;
}

*::-webkit-scrollbar {
  width            : 4px;
  height           : 8px;
  background-color : $light;
}

*::-webkit-scrollbar-thumb {
  border-radius      : 4px;
  background-color   : grey;
}
