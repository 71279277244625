//  Media for max-width: 1700px

@media (max-width: 1700px) {
  footer .P-row .P-middle {
    width : 60%;
  }
  .P-footer-end {
    width : 58%;
  }
}

//  Media for max-width: 1500px

@media (max-width: 1500px) {
  footer .P-row .P-middle {
    width : 65%;
  }
  .P-footer-end {
    width     : 66%;
    max-width : 66%;
  }
}
//  Media for max-width: 1300px

@media (max-width: 1300px) {
  footer {
    padding : 20px 50px;

    .G-header-logo {
      img {
        width : 150px;
      }
    }

    .P-footer-contact {
      a {
        width : 100px;
      }
    }
  }

  footer .P-row:first-child {
    display        : flex;
    flex-direction : column;
    .P-middle {
      width  : 100%;
      margin : 25px 0;
    }
  }
  footer .P-row .P-right img {
    margin : 0 15px;
  }
  .P-footer-end {
    width     : 75%;
    max-width : 100%;
  }
}
//  Media for max-width: 1200px

@media (max-width: 991px) {
  footer .P-row:last-child {
    display        : flex;
    flex-direction : column;
    align-items    : center !important;
    .P-right {
      margin-top      : 35px;
      justify-content : center;
      align-items     : center;
    }
  }
  .P-footer-end {
    width       : 100%;
    align-items : flex-start;
  }
}


@media (max-width: 768px) {
  footer {
    padding : 20px 20px;
    position    : relative;
    
    .G-header-logo {
      padding-top : 40px;
      margin-left: 10px;
      img {
        width : 100px;
      }
    }
    .P-footer-link {
      p {
        display : none;
      }
    }

    .P-footer-contact-info {
      position : absolute;
      top      : 0;
      right    : -25%;

      div {
        margin-top : 20px;
        span {
          font-size : 12px !important;
        }

        img {
          width : 15px !important;
        }
      }
    }

    .P-footer-contact {
      a {
        display : none;
      }
    }

    .P-footer-end {
      margin-top: 0 !important;
      p {
        font-size : 12px;
      }
    }
  }
}
