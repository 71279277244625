@import "../../assets/styles/variables.scss";

footer {
  padding          : 0 200px;
  background-color : $main-green;
  padding-top      : 20px;
  overflow: hidden;
  .G-header-logo {
    img {
      width : 200px;
    }
  }

  .P-footer-contact {
    width     : 350px;
    max-width : 100%;

    a {
      min-width     : 150px;
      font-size     : 16px;
      margin-bottom : 20px;
      font-weight   : bold;
      color         : white;
      
      img {
        width : 35px;
      }

      &:hover {
        color: $main-gold;
      }
    }
  }

  .P-footer-link {
    width : 50%;
    p {
      font-size : 18px;
      color     : white;
    }

    .P-footer-contact-info {
      width : auto;

      > div {
        min-width : 250px;

        span {
          font-size: 18px;
          color : white;
        }

      }
    }
  }
    
  .P-footer-end {
    width      : 100%;
    border-top : 0.5px solid $light;
    opacity    : 0.5;
    // padding    : 20px 0;

    p {
      color : white;
    }
  }
}