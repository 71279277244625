@import "./variables";
@import "./normalize";

.G-capitalize {
  text-transform : capitalize;
}

.G-uppercase {
  text-transform : uppercase;
}
h1,
h2 {
  letter-spacing : 0;
}

.G-box-title {
  font-weight    : bold;
  text-transform : uppercase;
  color          : $gray;
  font-size      : 22px;
  line-height    : 26px;
  margin-left    : -65px;
  @media (max-width: 1300px) {
    margin-left : 0;
  }
  @media (max-width: 576px) {
    font-size      : 18px;
    line-height    : 22px;
    letter-spacing : 1.76px;
  }
}

.G-bold {
  font-weight : bold;
}

.G-markdown {
  a {
    color : $light-yellow;

    &:hover {
      color : $light-yellow;
    }
  }

  &.G-white-color {
    * {
      color : $white;
    }
  }
}

.G-gray-dark-color {
  color : $gray-dark;
}

.G-main-color {
  color : $main-color;
}

.G-red-color {
  color : $red !important;
}

.G-gray-color {
  color : $gray;
}

.G-green-color {
  color : $green;
}

.G-white-color {
  color : $white;
}

.G-line {
  width            : 40px;
  height           : 2px;
  background-color : $purple;
}

.G-bg-image {
  width               : 100%;
  height              : 100%;
  background-size     : cover;
  background-position : center;
  background-repeat   : no-repeat;
}

.G-circle-image {
  width         : 35px;
  min-width     : 35px;
  height        : 35px;
  border-radius : 50%;
}

.G-half {
  width : calc(50% - 20px) !important;
}

.G-text-center {
  text-align : center;
}

.G-text-right {
  text-align : right;
}

.G-cursor {
  cursor : pointer;
}

// #region Font Size

$fonts: (
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  24,
  28,
  30,
  32,
  34,
  36,
  40,
  45,
  46,
  50,
  55,
  60,
  70,
  75,
  76
);

@each $size in $fonts {
  .G-fs-#{$size} {
    font-size : #{$size + px};
  }
}

// #endregion

// #region Flex

.G-flex {
  display : flex;
}

.G-inline-flex {
  display : inline-flex;
}

.G-center {
  justify-content : center;
  align-items     : center;
}

.G-justify-center {
  justify-content : center;
}

.G-justify-between {
  justify-content : space-between;
}

.G-justify-around {
  justify-content : space-around;
}

.G-justify-end {
  justify-content : flex-end;
}

.G-align-center {
  align-items : center;
}

.G-align-start {
  align-items : flex-start;
}

.G-align-end {
  align-items : flex-end;
}

.G-flex-column {
  flex-direction : column;
}

.G-flex-wrap {
  flex-wrap : wrap;
}
// #endregion

// #region Buttons

.G-btn {
  padding         : 15px 45px;
  display         : flex;
  align-items     : center;
  justify-content : center;
  position        : relative;
  font-weight     : bold;
  border-radius   : $radius;
  color           : $white;
  background      : $main-gold;
}

.G-btn-link {
  border-bottom : 2px solid $red;
  padding       : 7px 0;
  font-weight   : bold;
  color         : $red;
  display       : inline-flex;
  align-items   : center;
  position      : relative;
  z-index       : 1;
  transition    : all 0.3s;

  &::before {
    content          : "";
    z-index          : -1;
    transition       : all 0.3s;
    position         : absolute;
    background-color : $red;
    left             : 0;
    bottom           : 0;
    width            : 100%;
    height           : 0%;
  }

  @media (max-width: 767px) {
    margin-left : 0;
  }

  i {
    margin-left : 60px;
    transition  : margin-left 0.3s;
    color       : inherit;
  }

  &:hover {
    color               : $white;
    border-bottom-color : $red;
    padding-left        : 10px;
    padding-right       : 10px;

    i {
      margin-left : 40px;
    }

    &::before {
      height : calc(100% + 2px);
    }
  }

  &.G-gray-btn {
    color               : $gray;
    border-bottom-color : $gray;

    &::before {
      background-color : $gray;
    }

    &:hover {
      color               : $white;
      border-bottom-color : $gray;
    }
  }

  &.G-white-btn {
    color               : $white;
    border-bottom-color : $white;

    &::before {
      background-color : $white;
    }

    &:hover {
      color               : $red;
      border-bottom-color : $white;
    }
  }
}

// #endregion

// #region Inputs

.G-input-group {
  width         : 100%;
  margin-bottom : 20px;

  p {
    margin-bottom : 5px;
    font-size     : 12px;
    color         : $gray;
  }
}

.G-input {
  width            : 100%;
  height           : $input-height;
  border           : 0;
  border-bottom    : 1px solid $gray;
  text-indent      : 10px;
  background-color : transparent;
}

.G-textarea {
  height      : calc(#{$input-height} + 10px);
  max-height  : 400px;
  min-height  : calc(#{$input-height} + 40px);
  padding     : 10px;
  text-indent : 0;
  resize      : vertical;
}

.G-error {
  box-shadow : 0 0 10px transparentize($red, 0.1) !important;
}

.G-input-col-2,
.G-input-col-3 {
  display     : flex;
  align-items : center;
  flex-wrap   : wrap;
}

.G-input-col-2 {
  .G-input-group {
    width        : calc(100% / 2 - 30px);
    margin-right : 60px;

    &:nth-child(2n) {
      margin-right : 0;
    }
  }
}

.G-input-col-3 {
  .G-input-group {
    width        : calc(100% / 3 - 40px);
    margin-right : 60px;

    &:nth-child(3n) {
      margin-right : 0;
    }
  }
}
// #endregion

// #region Sections

.G-block {
  width            : 100%;
  padding          : 20px;
  border-radius    : $radius;
  box-shadow       : $shadow;
  background-color : $white;
}

.G-block-title {
  margin-bottom : 30px;
  font-size     : 16px;
  font-weight   : bold;
}
// #endregion

// #region Checkbox directive

.G-checkbox-item {
  width            : 20px;
  height           : 20px;
  min-width        : 20px;
  min-height       : 20px;
  font-size        : 10px;
  font-weight      : bold;
  border-radius    : 3px;
  border           : 1px solid #bbbbbb;
  display          : flex;
  align-items      : center;
  justify-content  : center;
  cursor           : pointer;
  background-color : $white;
}

.G-checkbox-active {
  border           : 2px solid $main-color;
  color            : $white;
  background-color : $main-color;
}
// #endregion

// #region Radio directive

.G-radio-item {
  width           : 17px;
  height          : 17px;
  min-width       : 17px;
  min-height      : 17px;
  border-radius   : 50%;
  border          : 1px solid $main-color;
  display         : flex;
  align-items     : center;
  justify-content : center;
  position        : relative;
  cursor          : pointer;

  &.G-radio-active {
    &::before {
      content          : "";
      width            : calc(100% - 6px);
      height           : calc(100% - 6px);
      border-radius    : 50%;
      position         : absolute;
      top              : 3px;
      left             : 3px;
      background-color : $main-color;
    }
  }
}
// #endregion

// #region Modal

.G-modal {
  width            : 700px;
  padding          : 30px;
  background-color : $white;

  .G-modal-title {
    margin-bottom : 20px;
  }

  .G-modal-save-btn {
    margin-left : 20px;
  }
}

// #endregion

// #region Page

.G-page {
  width       : 100%;
  min-height  : 100vh;
  padding-top : 100px;
}

@media (max-width : 450px) {
  .G-page {
    padding-top: 75px;
  }
}

.G-container {
  // max-width : 1170px;
  width   : 100%;
  margin  : 0 auto;
  padding : 0 15px;
}

.G-big-container {
  width         : 100%;
  padding-left  : $big-padding;
  padding-right : $big-padding;
}

.G-gradient {
  
  &::after {
    content          : "";
    position         : absolute;
    left             : 0;
    bottom           : 0;
    width            : 100%;
    height           : 100%;
    background-image : linear-gradient(to bottom, transparent 0%, black 100%);
  }
}

.G-home-cover {
  height     : 100vh;
  min-height : 500px;
  // padding-top         : $header-height;
  background-size     : cover;
  background-position : center;
  background-repeat   : no-repeat;
  position            : relative;
  display             : flex;
  align-items         : center;
  justify-content     : center;
  transition          : margin 1s;

  .G-cover-content {
    position : relative;
    padding  : 0 13vw;
    z-index  : 1;
    height   : 100%;
  }

  video {
    position   : absolute;
    top        : 50%;
    left       : 50%;
    transform  : translateX(-50%) translateY(-50%);
    min-width  : 100%;
    min-height : 100%;
    width      : auto;
    height     : auto;
    z-index    : -1000;
    overflow   : hidden;
  }

  .P-home-animation {
    position  : absolute;
    left      : 50%;
    top       : 50%;
    transform : translate(-50%, -50%);
    // animation-name     : images-block;
    // animation-delay    : 3s;
    animation-duration : 3s;
    z-index            : 100;

    // animation-fill-mode : forwards;
    &.P-scroll-animation-hide {
      animation-name      : scaleHide;
      animation-duration  : 0.8s;
      animation-fill-mode : forwards;
    }

    &.P-scroll-animation-show {
      animation-name      : scaleShow;
      animation-duration  : 0.8s;
      animation-fill-mode : forwards;
    }

    .P-home-logo {
      width              : 150px;
      animation-name     : home-logo;
      animation-duration : 4.5s;
      // animation-fill-mode : forwards;
    }

    .P-home-title {
      width      : 250px;
      margin-top : 40px;
    }
  }

  @keyframes scaleHide {
    from {
      transform : translate(-50%, -50%) scale(1);
      opacity   : 1;
    }
    to {
      transform : translate(-50%, -50%) scale(0);
      opacity   : 0;
    }
  }
  @keyframes scaleShow {
    from {
      transform : translate(-50%, -50%) scale(0);
      opacity   : 0;
    }
    to {
      transform : translate(-50%, -50%) scale(1);
      opacity   : 1;
    }
  }
  @keyframes home-logo {
    from {
      transform : rotate(0);
    }
    to {
      transform : rotate(360deg);
    }
  }

  @keyframes images-block {
    from {
      left       : 50%;
      top        : 50%;
      opacity    : 1;
      visibility : visible;
    }
    to {
      left       : 10%;
      top        : 0%;
      opacity    : 0;
      visibility : hidden;
    }
  }

}

.G-cover {
  height     : 100vh;
  min-height : 500px;
  // padding-top         : $header-height;
  background-size     : cover;
  background-position : center;
  background-repeat   : no-repeat;
  position            : relative;
  display             : flex;
  align-items         : center;
  justify-content     : center;
  transition          : margin 1s;

  .G-cover-content {
    position : relative;
    padding  : 0 13vw;
    z-index  : 1;
    height   : 100%;
  }

  video {
    position   : absolute;
    top        : 50%;
    left       : 50%;
    transform  : translateX(-50%) translateY(-50%);
    min-width  : 100%;
    min-height : 100%;
    width      : auto;
    height     : auto;
    z-index    : -1000;
    overflow   : hidden;
  }

  .P-home-animation {
    position  : absolute;
    left      : 50%;
    top       : 50%;
    transform : translate(-50%, -50%);
    // animation-name     : images-block;
    // animation-delay    : 3s;
    animation-duration : 3s;
    z-index            : 100;

    // animation-fill-mode : forwards;
    &.P-scroll-animation-hide {
      animation-name      : scaleHide;
      animation-duration  : 0.8s;
      animation-fill-mode : forwards;
    }

    &.P-scroll-animation-show {
      animation-name      : scaleShow;
      animation-duration  : 0.8s;
      animation-fill-mode : forwards;
    }

    .P-home-logo {
      width              : 200px;
      animation-name     : home-logo;
      animation-duration : 4.5s;
      // animation-fill-mode : forwards;
    }

    .P-home-title {
      width      : 300px;
      margin-top : 40px;
    }
  }

  @keyframes scaleHide {
    from {
      transform : translate(-50%, -50%) scale(1);
      opacity   : 1;
    }
    to {
      transform : translate(-50%, -50%) scale(0);
      opacity   : 0;
    }
  }
  @keyframes scaleShow {
    from {
      transform : translate(-50%, -50%) scale(0);
      opacity   : 0;
    }
    to {
      transform : translate(-50%, -50%) scale(1);
      opacity   : 1;
    }
  }
  @keyframes home-logo {
    from {
      transform : rotate(0);
    }
    to {
      transform : rotate(360deg);
    }
  }

  @keyframes images-block {
    from {
      left       : 50%;
      top        : 50%;
      opacity    : 1;
      visibility : visible;
    }
    to {
      left       : 10%;
      top        : 0%;
      opacity    : 0;
      visibility : hidden;
    }
  }
}

.G-layer {
  position : relative;

  &::before {
    content          : "";
    position         : absolute;
    top              : 0;
    left             : 0;
    width            : 100%;
    height           : 100%;
    background-color : $white;
  }

  .G-layer-content {
    position : relative;
    z-index  : 1;
  }
}

// #endregion

// #region Margins

.G-mb-0 {
  margin-bottom : 0;
}
.G-mb-1 {
  margin-bottom : 10px;
}
.G-mb-2 {
  margin-bottom : 20px;
}
.G-mb-3 {
  margin-bottom : 30px;
}
.G-mb-4 {
  margin-bottom : 40px;
}
.G-mb-5 {
  margin-bottom : 50px;
}
.G-mb-6 {
  margin-bottom : 60px;
}
.G-mb-8 {
  margin-bottom : 80px;
}
.G-mb-10 {
  margin-bottom : 100px;
}

.G-mt-1 {
  margin-top : 0;
}
.G-mt-1 {
  margin-top : 10px;
}
.G-mt-2 {
  margin-top : 20px;
}
.G-mt-3 {
  margin-top : 30px;
}
.G-mt-4 {
  margin-top : 40px;
}
.G-mt-5 {
  margin-top : 50px;
}
.G-mt-6 {
  margin-top : 60px;
}

.G-mr-1 {
  margin-right : 0;
}
.G-mr-1 {
  margin-right : 10px;
}
.G-mr-2 {
  margin-right : 20px;
}
.G-mr-3 {
  margin-right : 30px;
}
.G-mr-4 {
  margin-right : 40px;
}
.G-mr-5 {
  margin-right : 50px;
}
.G-mr-6 {
  margin-right : 60px;
}

.G-ml-1 {
  margin-left : 0;
}
.G-ml-1 {
  margin-left : 10px;
}
.G-ml-2 {
  margin-left : 20px;
}
.G-ml-3 {
  margin-left : 30px;
}
.G-ml-4 {
  margin-left : 40px;
}
.G-ml-5 {
  margin-left : 50px;
}
.G-ml-6 {
  margin-left : 60px;
}

// #endregion

// #region Paddings

.G-pb-0 {
  padding-bottom : 0;
}
.G-pb-1 {
  padding-bottom : 10px;
}
.G-pb-2 {
  padding-bottom : 20px;
}
.G-pb-3 {
  padding-bottom : 30px;
}
.G-pb-4 {
  padding-bottom : 40px;
}
.G-pb-5 {
  padding-bottom : 50px;
}
.G-pb-6 {
  padding-bottom : 60px;
}

.G-pt-1 {
  padding-top : 0;
}
.G-pt-1 {
  padding-top : 10px;
}
.G-pt-2 {
  padding-top : 20px;
}
.G-pt-3 {
  padding-top : 30px;
}
.G-pt-4 {
  padding-top : 40px;
}
.G-pt-5 {
  padding-top : 50px;
}
.G-pt-6 {
  padding-top : 60px;
}

.G-pr-1 {
  padding-right : 0;
}
.G-pr-1 {
  padding-right : 10px;
}
.G-pr-2 {
  padding-right : 20px;
}
.G-pr-3 {
  padding-right : 30px;
}
.G-pr-4 {
  padding-right : 40px;
}
.G-pr-5 {
  padding-right : 50px;
}
.G-pr-6 {
  padding-right : 60px;
}

.G-pl-1 {
  padding-left : 0;
}
.G-pl-1 {
  padding-left : 10px;
}
.G-pl-2 {
  padding-left : 20px;
}
.G-pl-3 {
  padding-left : 30px;
}
.G-pl-4 {
  padding-left : 40px;
}
.G-pl-5 {
  padding-left : 50px;
}
.G-pl-6 {
  padding-left : 60px;
}

// #endregion
.G-lh-30 {
  line-height : 30px;
}

.G-gold {
  color : $main-gold;
}

.P-slider-padding {
  padding-left : 400px;
}

.P-green-line {
  width            : 100%;
  height           : 100px;
  background-color : $main-green;
}

@media (max-width: 450px) {
  .P-green-line {
    display: none;
  }
}