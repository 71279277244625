.P-modal {
  position : fixed;
  top      : 0;
  left     : 0;
  width    : 100%;
  height   : 100vh;

  .P-background {
    position         : absolute;
    top              : 0;
    left             : 0;
    bottom           : 0;
    right            : 0;
    background-color : rgba(0, 0, 0, 0.1);
  }

  .P-content {
    position         : absolute;
    top              : 50%;
    left             : 50%;
    transform        : translate(-50%, -50%);
    width            : 500px;
    padding          : 20px;
    background-color : white;
    text-align       : center;

    button {
      width : 100%;
    }

    img {
      margin  : 0 auto 10px;
      width   : 50px;
      display : block;
    }
  }
}


@media (max-width: 650px) {
  .P-modal {
    .P-content {
      width: 90%;
      p {
        font-size: 16px;
      }
    }
    h3 {
      font-size: 24px;
    }
  }
}
