
// #region Colors
$black        : #000000;
$white        : #ffffff;
$gray-dark    : #8E8E8E;
$gray         : #A2A3AD;
$grey-light   : #A7A9AD;
$light        : #F6F6F6;
$light-gray   : #cccccc;
$green        : #61CCA0;
$light-green  : #0bbf6b;
$red          : #FA3441;
$blue         : #141E66;
$el-blue      : #4FDDD9;
$light-red    : #dc444e;
$yellow       : #F6AD22;
$light-yellow : #fbc43a;
$orange       : #F0641E;
$purple       : #8107C3;
$dark         : #232733;
$main-green   : #002b19;
$main-gold    : #DDA54C;
// #endregion

// #region Alternative styles

$gradient         : linear-gradient(to right, #03C4B5, $el-blue);
$shadow           : 0 0 10px rgba(0, 0, 0, .1);
$error-shadow     : 0 0 15px transparentize($red, 0.6);
$blue-shadow      : 0 0 40px rgba($blue, .2);
$blue-dark-shadow : 0 0 40px rgba($blue, .4);
$radius           : 3px;
$btn-radius       : 20px;

$laptop-size : 1024px;
$tab-size    : 768px;
$phone-size  : 520px;

// #endregion

// #region App Components styles
$body-color : $white;
$main-color : $el-blue;

$modal-z      : 1000;
$right-side-z : $modal-z + 1;
$header-z     : 500;

$left-side-width : 120px;
$header-height   : 100px;
$padding-vw      : 8vw;
$header-padding  : 8%;
$padding         : 12%;
$big-padding     : 15%;
$input-height    : 40px;
$main-padding    : 0 100px;
$main-gradient   : linear-gradient(to bottom, transparent 60%, rgb(250, 253, 250) 100%);

// #endregion
